// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cm-js": () => import("./../../../src/pages/cm.js" /* webpackChunkName: "component---src-pages-cm-js" */),
  "component---src-pages-em-js": () => import("./../../../src/pages/em.js" /* webpackChunkName: "component---src-pages-em-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-km-js": () => import("./../../../src/pages/km.js" /* webpackChunkName: "component---src-pages-km-js" */),
  "component---src-pages-mm-js": () => import("./../../../src/pages/mm.js" /* webpackChunkName: "component---src-pages-mm-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

